import CARMap from "../../assets/images/plantImage/CAR.jpeg";
import ATLMap from "../../assets/images/plantImage/ATL.png";
import DETMap from "../../assets/images/plantImage/DET.png";
import ALAMap from "../../assets/images/plantImage/ALA.png";
import HAZMap from "../../assets/images/plantImage/HAZ.png";
import HOUMap from "../../assets/images/plantImage/HOU.png";
import LASMap from "../../assets/images/plantImage/LAS.png";
import MIAMap from "../../assets/images/plantImage/MIA.png";
import TACMap from "../../assets/images/plantImage/TAC.png";
import JAXMap from "../../assets/images/plantImage/JAX.png";
import LANMap from "../../assets/images/plantImage/LAN.png";
import ST3Map from "../../assets/images/plantImage/ST3.png";
import BALMap from "../../assets/images/plantImage/BAL.png";
import TEMMap from "../../assets/images/plantImage/TEM.png";
import KC2Map from "../../assets/images/plantImage/KC2.png";
import MORMap from "../../assets/images/plantImage/MOR.png";
import LOUMap from "../../assets/images/plantImage/LOU.png";
import BAYMap from "../../assets/images/plantImage/BAY.png";
import RCHMap from "../../assets/images/plantImage/RCH.png";
import COLMap from "../../assets/images/plantImage/COL.png";
import KNCMap from "../../assets/images/plantImage/KNC.png";
import BLMMap from "../../assets/images/plantImage/BLM.png";
import KENMap from "../../assets/images/plantImage/KEN.png";
import SANMap from "../../assets/images/plantImage/SAN.png";
import SL2Map from "../../assets/images/plantImage/SL2.png";
import MESMap from "../../assets/images/plantImage/MES.png";
import MISMap from "../../assets/images/plantImage/MIS.png";
import PITMap from "../../assets/images/plantImage/PIT.png";
import MTYMap from "../../assets/images/plantImage/MTY.png";
import OKCMap from "../../assets/images/plantImage/OKC.png";
import MXCMap from "../../assets/images/plantImage/MXC.png";
import NORMap from "../../assets/images/plantImage/NOR.png";
import RIAMap from "../../assets/images/plantImage/RIA.png";
// HTML Maps
import ALALMap from "../../assets/images/htmlmaps/ALA.jpg";
import ATLLMap from "../../assets/images/htmlmaps/ATL.jpg";
import LASLMap from "../../assets/images/htmlmaps/LAS.jpg";
import HAZLMap from "../../assets/images/htmlmaps/HAZ.jpg";

import ReactDOMServer from "react-dom/server";
import L from "leaflet";
import momentTZ from "moment-timezone";
import { userDataObject, getPlantCoordinates, DB_ERROR } from "./constants";

export const statusColorRenderer = (status) => {
  switch (status) {
    case "Wait Qty Check":
      return "#008300";
    case "Scheduled":
      return "#4647FE";
    case "Wait Dock Cnfrm":
      return "#D2B623";
    case "Wait Start Cnfrm":
      return "#7C4807";
    case "Enqueued To Start":
      return "#FF88FF";
    case "Started":
      return "#8D8D8D";
    case "Staged":
      return "#FF8903";
    case "Suspended":
      return "#7B037F";
    case "Traffic":
      return "#D96E0B";
    case "Alarm":
      return "#EE3232";
    case "Removed":
      return "#000000";
    case "Charging":
      return "#83C4D9";
    case "Iddle":
      return "#008000";
    case "Idle":
      return "#008000";
    case "LGV In Manual":
      return "#9D4B4B";
    case "ActiveFull":
      return "#0000FF";
    case "ActiveEmpty":
      return "#008000";
    default:
      return "#000000";
  }
};

export const isTokenExpired = () => {
  let auth = JSON.parse(localStorage.getItem("isAuthExpiry"));
  if (!auth) {
    return true;
  }
  let expiryTime = new Date(auth.expiryTime);
  let currentTime = new Date();
  if (expiryTime < currentTime) {
    return true;
  }
  return false;
};

export const externalLinkNavigator = (link) => {
  const quickLinks = {
    logistics:
      "https://app.powerbi.com/groups/me/reports/64fa4bc6-35a9-48ea-bdf1-af49555a6d7f/ReportSectionc1d9f7cba778eee595ac?ctid=773bda68-87c6-401a-b9a4-3dfb1f92e189",
    performance:
      "http://pdc-whse-perf:8080/d/wWbpz254z/lgv_one_summary?orgId=1&refresh=1m&var-WHSE=CAR_LGV",
    shipment:
      "http://pdc-whse-perf:8080/d/G7En7Wp4z/shipment-progress?orgId=1&refresh=5s&var-WHSE=CAR_LGV",
    downtime:
      "http://pdc-whse-perf:8080/d/ESqcrJ-4z/lgv-alarm-summary?orgId=1&var-WHSE=CAR_LGV",
    pairing:
      "http://pdc-whse-perf:8080/d/Tdz-8g84k/active-mixed-pairing?orgId=1&refresh=5s",
    yardView: `/${getLocalStorageItem("locationCode")}/yard-view`,
    timeMachine: `/${getLocalStorageItem("locationCode")}/time-machine`,
    allocatedView: `/${getLocalStorageItem("locationCode")}/allocated-view`,
    navHeatmap: `/${getLocalStorageItem("locationCode")}/nav-heat-map`,
  };
  return quickLinks[link];
};

export const authExpirationNavigator = (errorApi) => {
  if (errorApi > 4) {
    localStorage.setItem("isAuthExpiry", JSON.stringify(true));
    reloadApp();
  }
};

const SIMPLE_MODE_COLORS = {
  CanAllocate: "#767676",
  Allocated: "#767676",
  Released: "#767676",
  Started: "#767676",
  InTransit: "#767676",
  Completed: "#309218",
  New: "#FF5733",
  Parked: "#FF5733",
};

const DOCK_DOOR_STATUS_COLORS = {
  CanAllocate: "#D90000",
  Allocated: "#767676",
  Released: "#AD9200",
  Started: "#0000D9",
  InTransit: "#0000D9",
  Completed: "#309218",
  New: "#FF5733",
  Parked: "#FF5733",
};

export const dockDoorStatusColor = (status, mode) => {
  if (!status) return "#ffffff";

  const colorMapping =
    mode === "SIMPLE" ? SIMPLE_MODE_COLORS : DOCK_DOOR_STATUS_COLORS;
  return colorMapping[status] || "#ffffff";
};

export const getAdjustedCoordinates = () => {
  let levels = [
    { start: 500, end: 800, level: { x: 56, y: 16 } },
    { start: 801, end: 1200, level: { x: 56, y: 16 } },
    { start: 1201, end: 1400, level: { x: 56, y: 16 } },
    { start: 1401, end: 1600, level: { x: 20, y: 17 } },
    { start: 1601, end: 1800, level: { x: 120, y: 19 } },
    { start: 1801, end: 2000, level: { x: 170, y: 22 } },
    { start: 2001, end: 2200, level: { x: 290, y: 24 } },
    { start: 2201, end: 2400, level: { x: 426, y: 26 } },
    { start: 2401, end: 2600, level: { x: 476, y: 28 } },
    { start: 2601, end: 2800, level: { x: 630, y: 26 } },
  ];
  let windowWidth = window.innerWidth;
  let matchingWindowLevel = levels.find(
    (level) => windowWidth > level.start && windowWidth < level.end
  );
  return matchingWindowLevel;
};

export const getAdjustedLineProductionCoordinates = () => {
  let levels = [
    { start: 500, end: 800, level: { x: 75, y: 170 } },
    { start: 801, end: 1200, level: { x: 240, y: 170 } },
    { start: 1201, end: 1400, level: { x: 75, y: 170 } },
    { start: 1401, end: 1600, level: { x: 60, y: 170 } },
    { start: 1601, end: 1800, level: { x: 60, y: 170 } },
    { start: 1801, end: 2000, level: { x: 110, y: 170 } },
    { start: 2001, end: 2200, level: { x: 230, y: 170 } },
    { start: 2201, end: 2400, level: { x: 610, y: 170 } },
    { start: 2401, end: 2600, level: { x: 650, y: 170 } },
    { start: 2601, end: 2800, level: { x: 550, y: 170 } },
  ];
  let windowWidth = window.innerWidth;
  let matchingWindowLevel = levels.find(
    (level) => windowWidth > level.start && windowWidth < level.end
  );
  return matchingWindowLevel;
};

export const getAdjustedDockDoorCoordinates = () => {
  let levels = [
    { start: 0, end: 200, level: { x: 0, y: 40 } },
    { start: 201, end: 500, level: { x: 0, y: 40 } },
    { start: 501, end: 800, level: { x: 0, y: 40 } },
    { start: 801, end: 1200, level: { x: 0, y: 40 } },
    { start: 1201, end: 1400, level: { x: 0, y: 40 } },
    { start: 1401, end: 1600, level: { x: 0, y: 40 } },
    { start: 1601, end: 1800, level: { x: 120, y: 40 } },
    { start: 1801, end: 2000, level: { x: 180, y: 40 } },
    { start: 2001, end: 2200, level: { x: 300, y: 40 } },
    { start: 2201, end: 2400, level: { x: 650, y: 40 } },
    { start: 2401, end: 2600, level: { x: 650, y: 40 } },
    { start: 2601, end: 2800, level: { x: 650, y: 40 } },
  ];
  let windowWidth = window.innerWidth;
  let matchingWindowLevel = levels.find(
    (level) => windowWidth > level.start && windowWidth < level.end
  );
  return matchingWindowLevel;
};

export const getScreenSize = () => {
  return window.innerWidth;
};

export const setDynamicZoomLevelToMap = (scaleLevel, mapType) => {
  switch (mapType) {
    case "indoor":
      switch (scaleLevel) {
        case "1.0": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(1.0)";
          break;
        }
        case "1.2": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(1.2) translate(120px, 50px)";
          break;
        }
        case "1.4": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(1.4) translate(210px, 90px)";
          break;
        }
        case "1.6": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(1.6) translate(265px, 120px)";
          break;
        }
        case "1.8": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(1.8) translate(325px, 135px)";
          break;
        }
        case "2.0": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(2.0) translate(360px, 155px)";
          break;
        }
        case "2.2": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(2.2) translate(380px, 175px)";
          break;
        }
        case "2.4": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(2.4) translate(400px, 195px)";
          break;
        }
        case "2.6": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(2.6) translate(420px, 215px)";
          break;
        }
        case "2.8": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(2.8) translate(440px, 235px)";
          break;
        }
        case "3.0": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(3.0) translate(460px, 255px)";
          break;
        }
        default: {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(1.0)";
          break;
        }
      }
      break;

    case "timeMachine":
      switch (scaleLevel) {
        case "1.0": {
          document.getElementById(
            "time-machine-map-id-reference"
          ).style.transform = "scale(1.0)";
          break;
        }
        case "1.2": {
          document.getElementById(
            "time-machine-map-id-reference"
          ).style.transform = "scale(1.2) translate(120px, 50px)";
          break;
        }
        case "1.4": {
          document.getElementById(
            "time-machine-map-id-reference"
          ).style.transform = "scale(1.4) translate(210px, 90px)";
          break;
        }
        case "1.6": {
          document.getElementById(
            "time-machine-map-id-reference"
          ).style.transform = "scale(1.6) translate(275px, 120px)";
          break;
        }
        case "1.8": {
          document.getElementById(
            "time-machine-map-id-reference"
          ).style.transform = "scale(1.8) translate(325px, 135px)";
          break;
        }
        case "2.0": {
          document.getElementById(
            "time-machine-map-id-reference"
          ).style.transform = "scale(2.0) translate(360px, 155px)";
          break;
        }
        default: {
          document.getElementById(
            "time-machine-map-id-reference"
          ).style.transform = "scale(1.0)";
          break;
        }
      }
      break;

    default:
      break;
  }
};

export const isScreenBigger = () => {
  if (Math.round(((window.outerWidth - 10) / window.innerWidth) * 100) <= 50) {
    setLocalStorageItem("isScreenBigger", true);
    return true;
  }
  return false;
};

export const getHeatmapWeightage = (length) => {
  if (length < 1) {
    return { fillOpacity: "5%", fillColor: "white" };
  } else if (length <= 1 && length < 10) {
    return { fillOpacity: "10%", fillColor: "red" };
  } else if (length >= 10 && length < 15) {
    return { fillOpacity: "15%", fillColor: "red" };
  } else if (length >= 15 && length < 20) {
    return { fillOpacity: "25%", fillColor: "red" };
  } else if (length >= 25 && length < 30) {
    return { fillOpacity: "45%", fillColor: "red" };
  } else if (length >= 30) {
    return { fillOpacity: "65%", fillColor: "red" };
  }
};

export const timeFrameFormatter = (timeMachineTimeFrames) => {
  let convertedFromDate = `${timeMachineTimeFrames?.fromDate}T${timeMachineTimeFrames?.fromTime}:00.000Z`;
  let convertedToDate = `${timeMachineTimeFrames?.toDate}T${timeMachineTimeFrames?.toTime}:00.000Z`;
  const { fromTimePlant, toTimePlant } = convertLocaleToPlantTimeZone(
    convertedFromDate,
    convertedToDate
  );
  return { fromTimePlant, toTimePlant };
};

export const apiHeadersFormatter = (accessToken) => {
  return {
    headers: {
      authorization: `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET",
      "Access-Control-Allow-Headers": "Content-Type",
    },
  };
};

export const getLocalStorageItem = (objectName) => {
  if (localStorage.getItem(objectName) !== undefined) {
    return JSON.parse(localStorage.getItem(objectName));
  }
};

export const setLocalStorageItem = (objectName, value) => {
  localStorage.setItem(objectName, JSON.stringify(value));
};

export const clearLocalStorageItem = (objectName) => {
  localStorage.removeItem(objectName);
};

export const reloadApp = () => {
  window.location.reload();
};

export const timeStampMinuteToHourFormatter = (time) => {
  let totalTimeParts = time.split(":");
  if (totalTimeParts.length === 2) {
    return `${totalTimeParts[0]}:${totalTimeParts[1]}`;
  } else {
    return `${totalTimeParts[1]}:${totalTimeParts[2]}`;
  }
};

export const secondToHourMinSecondFormatter = (seconds) => {
  // Calculate hours, minutes, and remaining seconds
  const hours = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const remainingSeconds = (seconds % 60).toString().padStart(2, "0");

  // Return the formatted time string
  return `${hours}:${minutes}:${remainingSeconds}`;
};

export const formatTime = (inputTime) => {
  const [hoursStr, minutesStr, secondsStr] = inputTime.split(":");
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);
  const seconds = parseInt(secondsStr, 10);

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

//Plant Image
export const getPlantDetails = (plantCode, featureType) => {
  const plantDetails = {
    NOR: {
      image: NORMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -12000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    RIA: {
      image: RIAMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -12000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    CAR: {
      image: CARMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -12000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    ATL: {
      image: ATLMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    DET: {
      image: DETMap,
      bounds: {
        leftBottom: { lat: 13000, lng: -35000 },
        rightTop: { lat: 510000, lng: 176000 },
      },
    },
    ALA: {
      image: ALAMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    // LAYOUT_START_X	15000
    // LAYOUT_END_X	333000
    // LAYOUT_START_Y	0
    // LAYOUT_END_Y	143000
    HAZ: {
      image: HAZMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 680000, lng: 153000 },
      },
    },
    HOU: {
      image: HOUMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 480000, lng: 153000 },
      },
    },
    LAS: {
      image: LASMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    MIA: {
      image: MIAMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    TAC: {
      image: TACMap,
      bounds: {
        leftBottom: { lat: 140000, lng: -15500 },
        rightTop: { lat: 429000, lng: 152500 },
      },
    },
    JAX: {
      image: JAXMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 570000, lng: 160000 },
      },
    },
    LAN: {
      image: LANMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    ST3: {
      image: ST3Map,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    BAL: {
      image: BALMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    KC2: {
      image: KC2Map,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    MOR: {
      image: MORMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    LOU: {
      image: LOUMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    TEM: {
      image: TEMMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    BAY: {
      image: BAYMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    COL: {
      image: COLMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 340000, lng: 153000 },
      },
    },
    KNC: {
      image: KNCMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    RCH: {
      image: RCHMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    BLM: {
      image: BLMMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 169000 },
      },
    },
    KEN: {
      image: KENMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 169000 },
      },
    },
    SAN: {
      image: SANMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 169000 },
      },
    },
    SL2: {
      image: SL2Map,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 169000 },
      },
    },
    MES: {
      image: MESMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 169000 },
      },
    },
    MIS: {
      image: MISMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -12000 },
        rightTop: { lat: 444000, lng: 179000 },
      },
    },
    PIT: {
      image: PITMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -70000 },
        rightTop: { lat: 510000, lng: 150000 },
      },
    },
    MTY: {
      image: MTYMap,
      bounds: {
        leftBottom: { lat: 36000, lng: -30000 },
        rightTop: { lat: 508000, lng: 185000 },
      },
    },
    OKC: {
      image: OKCMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 13000 },
        rightTop: { lat: 510000, lng: 175000 },
      },
    },
    MXC: {
      image: MXCMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -90000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
  };

  const plantHeatMapDetails = {
    NOR: {
      image: NORMap,
      bounds: {
        leftBottom: { lat: 40000, lng: 40000 },
        rightTop: { lat: 416000, lng: 158000 },
      },
    },
    RIA: {
      image: RIAMap,
      bounds: {
        leftBottom: { lat: 40000, lng: 40000 },
        rightTop: { lat: 416000, lng: 158000 },
      },
    },
    CAR: {
      image: CARMap,
      bounds: {
        leftBottom: { lat: 40000, lng: 40000 },
        rightTop: { lat: 416000, lng: 158000 },
      },
    },
    ATL: {
      image: ATLMap,
      bounds: {
        leftBottom: { lat: 55000, lng: 45000 },
        rightTop: { lat: 450000, lng: 168000 },
      },
    },
    DET: {
      image: DETMap,
      bounds: {
        leftBottom: { lat: 55000, lng: 45000 },
        rightTop: { lat: 450000, lng: 168000 },
      },
    },
    ALA: {
      image: ALAMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 52000 },
        rightTop: { lat: 502000, lng: 178000 },
      },
    },
    HAZ: {
      image: HAZMap,
      bounds: {
        leftBottom: { lat: 48000, lng: 42000 },
        rightTop: { lat: 470000, lng: 170000 },
      },
    },
    HOU: {
      image: HOUMap,
      bounds: {
        leftBottom: { lat: 60000, lng: 39000 },
        rightTop: { lat: 470000, lng: 180000 },
      },
    },
    LAS: {
      image: LASMap,
      bounds: {
        leftBottom: { lat: 39000, lng: 45000 },
        rightTop: { lat: 480000, lng: 178000 },
      },
    },
    MIA: {
      image: MIAMap,
      bounds: {
        leftBottom: { lat: 40000, lng: 22000 },
        rightTop: { lat: 485000, lng: 168000 },
      },
    },
    TAC: {
      image: TACMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 40000 },
        rightTop: { lat: 482000, lng: 178000 },
      },
    },
    JAX: {
      image: JAXMap,
      bounds: {
        leftBottom: { lat: 42000, lng: 40000 },
        rightTop: { lat: 482000, lng: 170000 },
      },
    },
    LAN: {
      image: LANMap,
      bounds: {
        leftBottom: { lat: 40000, lng: 40000 },
        rightTop: { lat: 482000, lng: 172000 },
      },
    },
    ST3: {
      image: ST3Map,
      bounds: {
        leftBottom: { lat: 42000, lng: 40000 },
        rightTop: { lat: 482000, lng: 172000 },
      },
    },
    BAL: {
      image: BALMap,
      bounds: {
        leftBottom: { lat: 42000, lng: 45000 },
        rightTop: { lat: 485000, lng: 170000 },
      },
    },
    MOR: {
      image: MORMap,
      bounds: {
        leftBottom: { lat: 37000, lng: 32000 },
        rightTop: { lat: 480000, lng: 160000 },
      },
    },
    KC2: {
      image: KC2Map,
      bounds: {
        leftBottom: { lat: 38000, lng: 27000 },
        rightTop: { lat: 502000, lng: 173000 },
      },
    },
    TEM: {
      image: TEMMap,
      bounds: {
        leftBottom: { lat: 42000, lng: 22000 },
        rightTop: { lat: 480000, lng: 160000 },
      },
    },
    BAY: {
      image: BAYMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    LOU: {
      image: LOUMap,
      bounds: {
        leftBottom: { lat: 60000, lng: 25000 },
        rightTop: { lat: 500000, lng: 190000 },
      },
    },
    COL: {
      image: COLMap,
      bounds: {
        leftBottom: { lat: 42000, lng: 40000 },
        rightTop: { lat: 480000, lng: 178000 },
      },
    },
    KNC: {
      image: KNCMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 43000 },
        rightTop: { lat: 512000, lng: 168000 },
      },
    },
    RCH: {
      image: RCHMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 40000 },
        rightTop: { lat: 482000, lng: 178000 },
      },
    },
    BLM: {
      image: BLMMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 40000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    KEN: {
      image: KENMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 24000 },
        rightTop: { lat: 512000, lng: 188000 },
      },
    },
    SAN: {
      image: SANMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 30000 },
        rightTop: { lat: 512000, lng: 180000 },
      },
    },
    SL2: {
      image: SL2Map,
      bounds: {
        leftBottom: { lat: 38000, lng: 30000 },
        rightTop: { lat: 512000, lng: 180000 },
      },
    },
    MES: {
      image: MESMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 50000 },
        rightTop: { lat: 512000, lng: 190000 },
      },
    },
    MIS: {
      image: MISMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 32000 },
        rightTop: { lat: 512000, lng: 188000 },
      },
    },
    PIT: {
      image: PITMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 75000 },
        rightTop: { lat: 512000, lng: 231000 },
      },
    },
    MTY: {
      image: MTYMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 35000 },
        rightTop: { lat: 512000, lng: 191000 },
      },
    },
    OKC: {
      image: OKCMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 35000 },
        rightTop: { lat: 512000, lng: 191000 },
      },
    },
    MXC: {
      image: MXCMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 35000 },
        rightTop: { lat: 512000, lng: 201000 },
      },
    },
  };

  const overlayMapDetails = {
    NOR: {
      image: NORMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    RIA: {
      image: RIAMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    CAR: {
      image: CARMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    ATL: {
      image: ATLLMap,
      bounds: {
        leftBottom: { lat: 14000, lng: -18000 },
        rightTop: { lat: 512000, lng: 160000 },
      },
    },
    DET: {
      image: DETMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    ALA: {
      image: ALALMap,
      bounds: {
        leftBottom: { lat: 14000, lng: -24000 },
        rightTop: { lat: 534000, lng: 170000 },
      },
    },
    HAZ: {
      image: HAZLMap,
      bounds: {
        leftBottom: { lat: 14000, lng: -22000 },
        rightTop: { lat: 510000, lng: 165000 },
      },
    },
    HOU: {
      image: HOUMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    LAS: {
      image: LASLMap,
      bounds: {
        leftBottom: { lat: 17000, lng: -23000 },
        rightTop: { lat: 536000, lng: 160000 },
      },
    },
    MIA: {
      image: MIAMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    TAC: {
      image: TACMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    JAX: {
      image: JAXMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    LAN: {
      image: LANMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    ST3: {
      image: ST3Map,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    BAL: {
      image: BALMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    KC2: {
      image: KC2Map,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    TEM: {
      image: TEMMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    BAY: {
      image: BAYMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    COL: {
      image: COLMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    RCH: {
      image: RCHMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    BLM: {
      image: BLMMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    KEN: {
      image: KENMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 169000 },
      },
    },
    MIS: {
      image: MISMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 169000 },
      },
    },
  };

  if (featureType === "indoorMap") {
    return plantDetails[plantCode];
  } else if (featureType === "mapOverlay") {
    if (plantCode === "ATL" || "ALA") {
      return overlayMapDetails[plantCode];
    }
  } else {
    return plantHeatMapDetails[plantCode];
  }
};

export const convertLocaleToPlantTimeZone = (
  convertedFromDate,
  convertedToDate
) => {
  if (convertedFromDate && convertedToDate) {
    let fromDate = new Date(convertedFromDate);
    let toDate = new Date(convertedToDate);
    let timeZone = getLocalStorageItem("timeZone");

    const plantTimeZoneFromDate = fromDate.toLocaleString("en-US", {
      timeZone: timeZone,
      timeStyle: "medium",
      dateStyle: "medium",
      hourCycle: "h24",
    });
    const plantTimeZoneToDate = toDate.toLocaleString("en-US", {
      timeZone: timeZone,
      timeStyle: "medium",
      dateStyle: "medium",
      hourCycle: "h24",
    });

    const convert = (dateAndTime) => {
      return new Date(dateAndTime).toISOString();
    };

    let fromTimePlant = convert(plantTimeZoneFromDate);
    let toTimePlant = convert(plantTimeZoneToDate);

    return { fromTimePlant, toTimePlant };
  }
};

const activePlants = [
  "Carolina",
  "Atlanta",
  "Detroit",
  "Alabama",
  "Hazleton",
  "Houston",
  "Las Vegas",
  "Miami",
  "Tacoma",
  "Jacksonville",
  "Lancaster",
  "Stockton 3",
  "Baltimore",
  "Kansas City 2",
  "Kansas City",
  "Mooresville",
  "Temple",
  "Louisville",
  "Baytown",
  "Richmond",
  "Columbus",
  "Bloomfield",
  "San Antonio",
  "Salt Lake City 2",
  "Bloomfield",
  "Mesa",
  "Kenosha",
  "Mississippi",
  "Pittsburgh",
  "Monterrey",
  "Oklahoma City",
  "Mexico City",
  "Bakersfield",
  "New Orleans",
  "Stockton 4",
  "Rialto",
  "Plainfield",
];

export const filterActivePlant = ({ data }) =>
  data.filter(({ name }) => activePlants.includes(name));

export const findDockDoorPosition = (plantConfig) => {
  let dockConfig = plantConfig?.indoorMap["dockDoors"];
  return dockConfig.position;
};

export const expireAuthToken = () => {
  setLocalStorageItem("isAuthExpiry", true);
  localStorage.clear();
  reloadApp();
};

export const getAvailablePlant = (locationCode) => {
  const availablePlantList = [
    "CAR",
    "ATL",
    "DET",
    "ALA",
    "HAZ",
    "HOU",
    "LAS",
    "MIA",
    "TAC",
    "JAX",
    "LAN",
    "ST3",
    "BAL",
    "KC2",
    "MOR",
    "TEM",
    "LOU",
    "RCH",
    "BAY",
    "COL",
    "KNC",
    "BLM",
    "KEN",
    "SAN",
    "SL2",
    "MES",
    "MIS",
    "PIT",
    "MTY",
    "OKC",
    "MXC",
    "NOR",
    "BAK",
    "RIA",
    "ST4",
    "PLA",
  ];
  if (availablePlantList.includes(locationCode)) {
    return true;
  } else {
    return false;
  }
};
/**
 * Up to Down
 * Increase the count, go down
 * Decrease the count, go up
 * Left to Right
 * Increase to count, go
 * Decrease the count, go
 */

export const getBottomTopCoordinates = () => {
  // return {
  //     rectBottomLeftXNew: 0,
  //     rectBottomLeftYNew: 594.453125,
  //     rectTopRightXNew: 1446,
  //     rectTopRightYNew: 5.453125
  // }
  return {
    rectBottomLeftXNew: 0,
    rectBottomLeftYNew: 500,
    rectTopRightXNew: 1446,
    rectTopRightYNew: 5.453125,
  };
};

export const isKafkaDataAvailable = () => {
  let plantAvailable = {
    CAR: false, //Not Available
    ATL: false,
    DET: false,
    ALA: false,
    HAZ: false,
    HOU: false,
    LAS: false,
    MIA: false,
    TAC: true,
    JAX: false,
    LAN: false,
    ST3: false,
    BAL: false,
    KC2: false,
    MOR: false,
    TEM: false,
    LOU: false,
    BAY: false,
    COL: false,
    KNC: false,
    BLM: false,
    KEN: false,
    SAN: false,
    SL2: false,
    MES: false,
    MIS: false,
    OKC: false,
    MXC: false,
    NOR: false,
    BAK: false,
    RIA: false,
    ST4: false,
    PLA: false,
  };
  return plantAvailable[getLocalStorageItem("locationCode")];
};

const plantList = {
  CAR: "Carolina",
  ATL: "Atlanta",
  DET: "Detroit",
  ALA: "Alabama",
  HAZ: "Hazleton",
  HOU: "Houston",
  LAS: "Las Vegas",
  MIA: "Miami",
  TAC: "Tacoma",
  JAX: "Jacksonville",
  LAN: "Lancaster",
  ST3: "Stockton 3",
  BAL: "Baltimore",
  KC2: "Kansas City 2",
  MOR: "Mooresville",
  TEM: "Temple",
  LOU: "Louisville",
  BAY: "Baytown",
  RCH: "Richmond",
  COL: "Columbus",
  KNC: "Kansas City",
  BLM: "Bloomfield",
  KEN: "Kenosha",
  SAN: "San Antonio",
  SL2: "Salt Lake City 2",
  MES: "Mesa",
  MIS: "Mississippi",
  PIT: "Pittsburgh",
  MTY: "Monterrey",
  OKC: "Oklahoma City",
  MXC: "Mexico City",
  NOR: "New Orleans",
  BAK: "Bakersfield",
  RIA: "Rialto",
  ST4: "Stockton 4",
  PLA: "Plainfield",
};
export const getPlantName = (shortName) => plantList[shortName];

export const zoomInHotPress = (keyPosition) => {
  document.getElementById(
    "indoor-map-id-reference"
  ).style.transform = `scale(1.6) translate(${keyPosition.y}px, ${keyPosition.x}px)`;
};

export const zoomOutHotPress = () => {};

// export const getBlockStoragePalletColor = () => {
//   const blockColorPallets = {
//     empty: "#B9B9B9",
//     occupiedSingle: "#42C721",
//     occupiedDouble: "#4133E1",
//     blockedDisabled: "#DDA0DD",
//     error: "#BF2E3A",
//   };
// };
export const filterDuplicateProductionLine = (lineData) => {
  if (lineData && lineData !== DB_ERROR) {
    const filteredObjects = lineData.reduce((accumulator, currentObject) => {
      const existingObject = accumulator.find(
        (obj) =>
          obj.LINE_NAME === currentObject.LINE_NAME &&
          obj.LINE === currentObject.LINE
      );

      if (
        !existingObject ||
        (currentObject.STATUS === "Finishing" &&
          existingObject.STATUS !== "Finishing")
      ) {
        return [
          ...accumulator.filter(
            (obj) =>
              !(
                obj.LINE_NAME === currentObject.LINE_NAME &&
                obj.LINE === currentObject.LINE
              )
          ),
          currentObject,
        ];
      }

      return accumulator;
    }, []);
    return filteredObjects;
  }
};

export const lgvNameRenderer = (vehicleNumber) => {
  if (vehicleNumber.includes("LGV")) {
    return vehicleNumber.split("V")[1];
  } else {
    return vehicleNumber;
  }
};

export const removeLgvs = () => {
  let lgvLayer = document.querySelectorAll("span.lgv-icon-style");
  for (let i = 0; i < lgvLayer.length; i++) {
    lgvLayer[i].remove();
  }
};

export const removeExistingProductionLines = () => {
  let productionLayerHolder = document.querySelectorAll(
    "div.line-prod-container"
  );
  productionLayerHolder.forEach((layer) => {
    layer.remove();
  });
};

export const removeDockDoorLayer = () => {
  let dockDoorLayer = document.querySelectorAll("div.dock-door");
  for (let i = 0; i < dockDoorLayer.length; i++) {
    dockDoorLayer[i].remove();
  }
};

export const trafficLgvDataGrouping = (trafficData) => {
  let trafficObject = {};
  trafficData.forEach((data, index) => {
    let objectName = data.LGV_ID;
    if (!trafficObject[objectName]) {
      trafficObject[objectName] = [];
    }
    trafficObject[objectName].push(data);
  });
  return trafficObject;
};

export const mapCoordinate = (
  coordinateX,
  coordinateY,
  renderObject,
  bounds
) => {
  let locationCode = getLocalStorageItem("locationCode");
  if (locationCode) {
    let startX;
    let startY;
    let endX;
    let endY;

    if (bounds !== undefined) {
      startX = bounds.startX;
      startY = bounds.startY;
      endX = bounds.endX;
      endY = bounds.endY;
    } else {
      const { rectBottomLeftX, rectBottomLeftY, rectTopRightX, rectTopRightY } =
        getPlantCoordinates(locationCode, renderObject);
      startX = rectBottomLeftX;
      startY = rectBottomLeftY;
      endX = rectTopRightX;
      endY = rectTopRightY;
    }

    const {
      rectBottomLeftXNew,
      rectBottomLeftYNew,
      rectTopRightXNew,
      rectTopRightYNew,
    } = getBottomTopCoordinates();

    let ratioX = (coordinateX - startX) / (endX - startX);
    let ratioY = (coordinateY - startY) / (endY - startY);

    let translatedX =
      rectBottomLeftXNew + ratioX * (rectTopRightXNew - rectBottomLeftXNew);
    let translatedY =
      rectBottomLeftYNew + ratioY * (rectTopRightYNew - rectBottomLeftYNew);

    return {
      x: translatedX,
      y: translatedY,
    };
  }
};

export const mapCoordinateReverse = (coordinateX, coordinateY, bounds) => {
  let locationCode = getLocalStorageItem("locationCode");
  if (locationCode) {
    let startX;
    let startY;
    let endX;
    let endY;

    if (bounds !== undefined) {
      startX = bounds.startX;
      startY = bounds.startY;
      endX = bounds.endX;
      endY = bounds.endY;
    }

    // const {
    //   rectBottomLeftXNew,
    //   rectBottomLeftYNew,
    //   rectTopRightXNew,
    //   rectTopRightYNew,
    // } = getBottomTopCoordinates();

    let rectBottomLeftXNew = 38784;
    let rectBottomLeftYNew = 51560;
    let rectTopRightXNew = 242723;
    let rectTopRightYNew = 207960;

    let ratioX = (coordinateX - startX) / (endX - startX);
    let ratioY = (coordinateY - startY) / (endY - startY);

    let translatedX = +ratioX * (rectTopRightXNew - rectBottomLeftXNew);
    let translatedY =
      rectBottomLeftYNew + ratioY * (rectTopRightYNew - rectBottomLeftYNew);

    return {
      x: translatedX,
      y: translatedY,
    };
  }
};

export const convertReactComponentToHtmlIcon = (
  reactComponent,
  className,
  iconSize,
  iconAnchor = null
) =>
  L.divIcon({
    className,
    iconSize,
    iconAnchor,
    html: ReactDOMServer.renderToString(reactComponent),
  });

export const convertReactComponentToStringHtml = (reactComponent) => {
  return ReactDOMServer.renderToString(reactComponent);
};

export const convertTimestampIntoHHMM = (timeStamp) => {
  if (!timeStamp) return "";
  let date = timeStamp.split("T");
  let time = date[1].split("Z");
  return `${date[0]} ${time[0]}`.split(":").slice(0, 2).join(":");
};

//Combine Kafka LGV data and Table LGV data
export const combineKafkaSocketAndLGVDetailsSocket = (
  kafkaDetails,
  lgvDetails
) => {
  //
  let kafkaData = kafkaDetails.filter(
    (lgv) => lgv.latitude !== "undefined" && lgv.longitude !== "undefined"
  );
  if (kafkaData !== "NO_DATA" || kafkaData !== undefined) {
    lgvDetails.forEach((lgv) => {
      kafkaData.forEach((kafkaLgv) => {
        let lgvNumber = kafkaLgv.vehicleNumber.split("V")[1];
        if (lgv.vehicleNumber === lgvNumber) {
          let currentPos = {
            lat: kafkaLgv.latitude,
            lng: kafkaLgv.longitude,
          };
          lgv.currentPos = currentPos;
        }
      });
    });
    return lgvDetails;
  } else {
    return lgvDetails;
  }
};

export const checkIsLocationCodeValid = (locationCode, locationCodeLocal) => {
  if (locationCode !== "" || locationCodeLocal !== null) {
    return true;
  } else {
    return false;
  }
};

export const checkIsTimeMachineEnabled = () => {
  if (getLocalStorageItem("isTimeMachineModeEnabled")) {
    return true;
  } else {
    return false;
  }
};

export const qualityStatusBadgeRenderer = (status) => {
  switch (status.toLowerCase()) {
    case "available":
      return "available-pallet";
    case "hold":
      return "hold-pallet";
    case "manuallabel":
      return "manual-pallet";
    case "scrap":
      return "scrap-pallet";
    case "shipped":
      return "shipped-pallet";
    case "removed":
      return "removed-pallet";
    case "empsale":
      return "empsale-pallet";
    case "nonconform":
      return "non-conform-pallet";
    case "received":
      return "received-pallet";
    case "quality":
      return "quality-pallet";
    case "destruction":
      return "destruction-pallet";
    case "consumed":
      return "consumed-pallet";
    case "error":
      return "error-pallet";
    case "incubation":
      return "incubation-pallet";
    default:
      return "null-pallet";
  }
};

const rotatePalletTextBasedOnRotation = (angle) => {
  if (angle == -90) {
    return "pallet-rotate-negative-90";
  }
  if (angle == 90) {
    return "pallet-rotate-90";
  }
  return "";
};

export const formatTimestamp = (rawTimeStamp) => {
  return rawTimeStamp.split(" ").splice(0, 4).reverse().splice(0, 3).join(" ");
};

export const isBlockRackNonLgvEnabled = () => {
  const enabledPlants = [
    //Group 1
    "CAR",
    "ATL",
    "HOU",
    "JAX",
    "BAY",
    //Group 2
    "TAC",
    "COL",
    "RCH",
    "MES",
    "MIS",
    //Group 3
    "SAN",
    "MTY",
    "OKC",
    "SL2",
    "MIA",
    //Group 4
    "BLM",
    "KEN",
    "HAZ",
    "LAN",
    "PIT",
    "ST3",
    "ALA",
    "KNC",
    "LOU",
    "BAL",
    "KC2",
    "LAS",
    "TEM",
    "MOR",
    "MXC",
    "DET",
    //Group 7
    "NOR",
    "BAK",
    "RIA",
    "ST4",
    "PLA",
  ];
  if (enabledPlants.includes(String(getLocalStorageItem("locationCode")))) {
    return true;
  } else {
    return false;
  }
};

export const getNonLgvPosition = () => {
  const plantsList = {
    CAR: [1630, 423],
    ATL: [50, 215],
    ALA: [1365, 110],
    HAZ: [495, 420],
    DET: [40, 163],
    LAS: [1630, 423],
    HOU: [140, 220],
    MIA: [1630, 423],
    BAY: [1370, 118],
    JAX: [1130, 53],
    SL2: [0, 0],
    ST3: [0, 0],
    //New set of plant to verify Vertical Racks
    TAC: [490, 165],
    COL: [0, 0],
    RCH: [20, 193],
    MES: [0, 0],
    MIS: [0, 0],
    NOR: [0, 0],
    RIA: [0, 0],
  };
  return plantsList[getLocalStorageItem("locationCode")];
};

export const isVerticalNonLgv = () => {
  const enabledPlants = ["ATL", "HAZ", "DET", "HOU", "JAX", "RCH", "TAC"];
  return enabledPlants.indexOf(getLocalStorageItem("locationCode")) > -1;
};

export const getBlockRackEmptyPositions = (blockRackList) => {
  let emptyCount = 0;
  blockRackList.forEach((item) => {
    if (blockRackTableDataValidation(item.lpn) === "null") {
      emptyCount += 1;
    }
  });
  return emptyCount;
};

export const blockRackTableDataValidation = (value) => {
  if (value) {
    if (value !== null && value.length !== 0) {
      return value;
    } else {
      return "null";
    }
  } else {
    return "null";
  }
};

// if rackType key is not present, then it's block data
const getPalletColors = ({
  rackType = "block",
  positionStorageBlocked,
  positionStorageDisabled,
  count,
  stackHeight,
  totalCount,
}) => {
  if (positionStorageBlocked) return "red";
  else if (positionStorageDisabled) return "pink";
  else {
    if (count === 0) return "grey";

    if ((!rackType && stackHeight && count < stackHeight) || count < totalCount)
      return "green";

    if (
      (!rackType && stackHeight && count === stackHeight) ||
      count === totalCount
    )
      return "blue";

    return "yellow";
  }
};

export const onDragging = (event) => {
  if (document.body.classList.contains("no-drag")) {
    return;
  }
  event.preventDefault();

  event.currentTarget.classList.add("active-shipment-status-container-on-drag");
};

export const onDraggingEnd = (event) => {
  event.preventDefault();
  if (document.body.classList.contains("no-drag")) {
    return;
  }
  event.currentTarget.classList.remove(
    "active-shipment-status-container-on-drag"
  );
};

export const replaceDotWithDash = (str) => {
  return str.replaceAll(".", "-");
};

export const checkIsPalletPositionValid = (totalPalletList, pallet, index) => {
  //Current Pallet Data
  let palletData;
  if (pallet[1]) {
    palletData = pallet[1][0];
  } else if (pallet[2]) {
    palletData = pallet[2][0];
  }

  if (index === 0) {
    if (palletData.palletPosition !== 1) {
      return false;
    }
  } else {
    let palletGroup;
    //Get the previous Pallet data
    if (totalPalletList[index - 1][1]) {
      palletGroup = totalPalletList[index - 1][1][0];
    } else if (totalPalletList[index - 1][2]) {
      palletGroup = totalPalletList[index - 1][2][0];
    }
    //Check current pallet position is n + 1th pallet position
    if (palletGroup.palletPosition + 1 === palletData.palletPosition) {
      return false;
    } else {
      return true;
    }
  }
};

export const preFillPalletPositionIfEmpty = (renderList) => {
  if (renderList) {
    const palletPositions = renderList.map((item) => {
      if (item[1]) return item[1][0]?.palletPosition;
      else if (item[2]) return item[2][0]?.palletPosition;
      return 0;
    });

    // Check is the list is reversed
    const isAscending =
      palletPositions[0] < palletPositions[palletPositions.length - 1];

    // Find the missing pallet position
    let missingPalletPosition;
    for (let i = 1; i <= Math.max(...palletPositions); i++) {
      if (!palletPositions.includes(i)) {
        missingPalletPosition = i;
        break;
      }
    }

    // Find the correct index to insert the missing pallet position
    let insertIndex;
    if (isAscending) {
      for (let i = 0; i < palletPositions.length; i++) {
        if (palletPositions[i] > missingPalletPosition) {
          insertIndex = i;
          break;
        }
      }
    } else {
      for (let i = 0; i < palletPositions.length; i++) {
        if (palletPositions[i] < missingPalletPosition) {
          insertIndex = i;
          break;
        }
      }
    }

    // Fill the missing pallet
    const newData = {};
    let inserted = false;
    let j = 0;
    for (let i = 0; i < palletPositions.length; i++) {
      if (i === insertIndex) {
        newData[j] = {
          palletPosition: missingPalletPosition,
          palletStatus: "empty",
        };
        j++;
        inserted = true;
      }
      newData[j] = renderList[i];
      j++;
    }
    // Do not add the new data to the end if the list is in reverse order
    if (isAscending && !inserted) {
      newData[j] = {
        palletPosition: missingPalletPosition,
        palletStatus: "empty",
      };
    }

    return newData;
  }
};

export const renderSinglePalletLocation = (pallet, index, blocksCount) => {
  if (pallet.palletStatus) {
    return renderDoubleEmptyPalletLocation(index);
  } else {
    return (
      <div key={index} className="pallet-row">
        {pallet[1] && pallet[1].length !== 0 ? (
          <div
            className={`pallet pallet-single ${getPalletColors(
              blocksCount[pallet[1][0].location][pallet[1][0].row][
                pallet[1][0].palletPosition
              ]
            )}`}
          >
            <p
              className={`${rotatePalletTextBasedOnRotation(
                blocksCount[pallet[1][0].location][pallet[1][0].row][
                  pallet[1][0].palletPosition
                ].angle
              )}`}
            >
              {/* {pallet[1][0].palletLevel} */}
              {
                blocksCount[pallet[1][0].location][pallet[1][0].row][
                  pallet[1][0].palletPosition
                ].count
              }
            </p>
          </div>
        ) : null}
        {pallet[2] && pallet[2].length !== 0 ? (
          <div
            className={`pallet pallet-single ${getPalletColors(
              blocksCount[pallet[2][0].location][pallet[2][0].row][
                pallet[2][0].palletPosition
              ]
            )}`}
          >
            <p
              className={`${rotatePalletTextBasedOnRotation(
                blocksCount[pallet[2][0].location][pallet[2][0].row][
                  pallet[2][0].palletPosition
                ].angle
              )}`}
            >
              {/* {pallet[2][0].palletLevel} */}
              {
                blocksCount[pallet[2][0].location][pallet[2][0].row][
                  pallet[2][0].palletPosition
                ].count
              }
            </p>
          </div>
        ) : null}
        {pallet[3] && pallet[3].length !== 0 ? (
          <div
            className={`pallet pallet-single ${getPalletColors(
              blocksCount[pallet[3][0].location][pallet[3][0].row][
                pallet[3][0].palletPosition
              ]
            )}`}
          >
            <p
              className={`${rotatePalletTextBasedOnRotation(
                blocksCount[pallet[3][0].location][pallet[3][0].row][
                  pallet[3][0].palletPosition
                ].angle
              )}`}
            >
              {/* {pallet[3][0].palletLevel} */}
              {
                blocksCount[pallet[3][0].location][pallet[3][0].row][
                  pallet[3][0].palletPosition
                ].count
              }
            </p>
          </div>
        ) : null}
      </div>
    );
  }
};

export const renderDoublePalletLocation = (pallet, index, blocksCount) => {
  if (pallet.palletStatus) {
    return renderSingleEmptyRowPalletLocation(index);
  } else {
    return (
      <div key={index} className="pallet-col">
        {pallet[1] && pallet[1].length !== 0 && (
          <div
            className={`pallet pallet-double ${getPalletColors(
              blocksCount[pallet[1][0].location][pallet[1][0].row][
                pallet[1][0].palletPosition
              ]
            )}`}
          >
            <p
              className={`${rotatePalletTextBasedOnRotation(
                blocksCount[pallet[1][0].location][pallet[1][0].row][
                  pallet[1][0].palletPosition
                ].angle
              )}`}
            >
              {
                blocksCount[pallet[1][0].location][pallet[1][0].row][
                  pallet[1][0].palletPosition
                ].count
              }
            </p>
          </div>
        )}
        {pallet[2] && pallet[2].length !== 0 && (
          <div
            className={`pallet pallet-double ${getPalletColors(
              blocksCount[pallet[2][0].location][pallet[2][0].row][
                pallet[2][0].palletPosition
              ]
            )}`}
          >
            <p
              className={`${rotatePalletTextBasedOnRotation(
                blocksCount[pallet[2][0].location][pallet[2][0].row][
                  pallet[2][0].palletPosition
                ].angle
              )}`}
            >
              {
                blocksCount[pallet[2][0].location][pallet[2][0].row][
                  pallet[2][0].palletPosition
                ].count
              }
            </p>
          </div>
        )}
        {pallet[3] && pallet[3].length !== 0 && (
          <div
            className={`pallet pallet-double ${getPalletColors(
              blocksCount[pallet[3][0].location][pallet[3][0].row][
                pallet[3][0].palletPosition
              ]
            )}`}
          >
            <p
              className={`${rotatePalletTextBasedOnRotation(
                blocksCount[pallet[3][0].location][pallet[3][0].row][
                  pallet[3][0].palletPosition
                ].angle
              )}`}
            >
              {
                blocksCount[pallet[3][0].location][pallet[3][0].row][
                  pallet[3][0].palletPosition
                ].count
              }
            </p>
          </div>
        )}
      </div>
    );
  }
};

export const countBlocksUsingLPNs = (socketData) => {
  let finalObj = socketData.reduce(function (acc, currentObj, i) {
    let locationName = currentObj.location;
    let cRow = currentObj.row;
    let cPalletPosition = currentObj.palletPosition;
    if (!(locationName in acc)) {
      acc[locationName] = {
        row: {},
      };
    }
    if (!acc[locationName][cRow]) acc[locationName][cRow] = {};
    if (!acc[locationName][cRow][cPalletPosition]) {
      acc[locationName][cRow][cPalletPosition] = {
        count: 0,
        totalCount: 0,
      };
    }
    if (currentObj.lpn) {
      acc[locationName][cRow][cPalletPosition]["count"] =
        acc[locationName][cRow][cPalletPosition]["count"] + 1;
    }
    acc[locationName][cRow][cPalletPosition]["totalCount"] =
      acc[locationName][cRow][cPalletPosition]["totalCount"] + 1;
    acc[locationName][cRow][cPalletPosition] = {
      ...acc[locationName][cRow][cPalletPosition],
      ...currentObj,
    };
    return acc;
  }, {});
  return finalObj;
};

export const countRacksUsingLPNs = (socketData) => {
  let finalObj = socketData.reduce(function (acc, currentObj, i) {
    let locationName = currentObj.location;
    locationName = locationName.split(".").slice(0, -1).join(".");
    let cRow = currentObj.row;
    let cPalletPosition = currentObj.palletPosition;

    if (!(locationName in acc)) {
      acc[locationName] = {
        row: {},
      };
    }

    if (!acc[locationName][cRow]) acc[locationName][cRow] = {};

    if (!acc[locationName][cRow][cPalletPosition]) {
      acc[locationName][cRow][cPalletPosition] = {
        count: 0,
        totalCount: 0,
      };
    }

    if (currentObj.lpn) {
      acc[locationName][cRow][cPalletPosition]["count"] =
        acc[locationName][cRow][cPalletPosition]["count"] + 1;
    }

    acc[locationName][cRow][cPalletPosition]["totalCount"] =
      acc[locationName][cRow][cPalletPosition]["totalCount"] + 1;

    acc = { ...acc, ...currentObj };
    return acc;
  }, {});
  return finalObj;
};

export const renderDoublePalletLocationWithReverseOrder = (
  pallet,
  index,
  blocksCount
) => {
  let firstChildOfPallet = Object.values(pallet)[0];
  let secondChildOfPallet = Object.values(pallet)[1];
  return (
    <div
      key={index}
      className={`${
        firstChildOfPallet && firstChildOfPallet[0]?.locationOrientation === "E"
          ? "pallet-col-reverse"
          : "pallet-col"
      }`}
    >
      {firstChildOfPallet && firstChildOfPallet[0] && (
        <div
          className={`pallet pallet-double ${getPalletColors(
            blocksCount[firstChildOfPallet[0]?.location]?.[
              firstChildOfPallet[0]?.row
            ]?.[firstChildOfPallet[0].palletPosition]
          )}
        `}
        >
          <p
            className={`${rotatePalletTextBasedOnRotation(
              blocksCount[firstChildOfPallet[0]?.location]?.[
                firstChildOfPallet[0]?.row
              ]?.[firstChildOfPallet[0]?.palletPosition]?.angle
            )}`}
          >
            {blocksCount[firstChildOfPallet[0]?.location]?.[
              firstChildOfPallet[0]?.row
            ]?.[firstChildOfPallet[0]?.palletPosition]?.count || 0}
          </p>
        </div>
      )}
      {secondChildOfPallet !== "empty" && secondChildOfPallet?.[0] && (
        <div
          className={`pallet pallet-double ${getPalletColors(
            blocksCount[secondChildOfPallet[0]?.location]?.[
              secondChildOfPallet[0]?.row
            ]?.[secondChildOfPallet[0].palletPosition]
          )}
          `}
        >
          <p
            className={`${rotatePalletTextBasedOnRotation(
              blocksCount[secondChildOfPallet[0]?.location]?.[
                secondChildOfPallet[0]?.row
              ]?.[secondChildOfPallet[0]?.palletPosition]?.angle
            )}`}
          >
            {blocksCount[secondChildOfPallet[0]?.location]?.[
              secondChildOfPallet[0]?.row
            ]?.[secondChildOfPallet[0]?.palletPosition]?.count || 0}
          </p>
        </div>
      )}
    </div>
  );
};

export const shouldRotatePallet = (angle, rackType) => {
  switch (rackType) {
    case "RACK_2":
      switch (angle) {
        case 90:
          return false;
        case -90:
          return false;
        case 180:
          return true;
        case 0:
          return true;
      }
    case "RACK":
      switch (angle) {
        case 90:
          return true;
        case -90:
          return true;
        case 180:
          return false;
        case 0:
          return false;
      }
  }
};

export const getPalletPositionOrRowCount = (totalPallets) => {
  let palletPositionGroup = {};
  totalPallets.forEach((pallet, index) => {
    if (!palletPositionGroup[pallet.palletPosition]) {
      palletPositionGroup[pallet.palletPosition] = [];
      palletPositionGroup[pallet.palletPosition].push(pallet);
    } else {
      palletPositionGroup[pallet.palletPosition].push(pallet);
    }
  });
  return palletPositionGroup;
};

export const getRackType = (rackData) => {
  const [firstPalletOfLocation] = rackData;
  if (firstPalletOfLocation) {
    const { rackType } = firstPalletOfLocation;
    return rackType ? rackType : null;
  }
};

export const groupRackWithRowAsKey = (rackData) => {
  let rackGroupWithRow = {};
  rackData.forEach((pallet) => {
    if (!rackGroupWithRow[pallet.row]) {
      rackGroupWithRow[pallet.row] = [];
    }
    rackGroupWithRow[pallet.row].push(pallet);
  });
  return rackGroupWithRow;
};

const groupRackWithPalletPositionAsKey = (rackData) => {
  let rackGroupWithPalletPosition = {};
  rackData.forEach((pallet) => {
    if (!rackGroupWithPalletPosition[pallet.palletPosition]) {
      rackGroupWithPalletPosition[pallet.palletPosition] = [];
    }
    rackGroupWithPalletPosition[pallet.palletPosition].push(pallet);
  });
  return rackGroupWithPalletPosition;
};

export const getMetaDataOfRackLocation = (rackData) =>
  Object.values(rackData)[0] ? Object.values(rackData)[0] : null;

export const getRacksCountByPallet = (
  sectionSingle,
  palletPosition,
  racksCount,
  row
) => {
  const locationName = sectionSingle.location.split(".").slice(0, -1).join(".");
  return racksCount?.[locationName]?.[row]?.[palletPosition]?.count || 0;
};

const renderPalletCountByRackType = (
  rackType,
  locationMetaData,
  key,
  racksCount
) => {
  switch (rackType) {
    case "RACK":
      return getRacksCountByPallet(locationMetaData, 1, racksCount, key);
    case "RACK_2":
      return getRacksCountByPallet(locationMetaData, key, racksCount, 1);
  }
};

const renderPalletPosition = (
  rackType,
  isPalletRotated,
  locationMetaData,
  racksCount,
  key,
  isPalletInThirdPosition
) => {
  return (
    <div
      className={`double-deep-rack-size ${
        isPalletInThirdPosition ? "three-pallet" : ""
      }`}
    >
      <p
        className={`pallet-text-size ${
          !isPalletRotated ? "rotate-text-double-deep-rack" : ""
        }`}
      >
        {renderPalletCountByRackType(
          rackType,
          locationMetaData,
          key,
          racksCount
        )}
      </p>
    </div>
  );
};

const getRackPositionWithOrientation = (
  firstPosition,
  secondPosition,
  thirdPosition,
  locationMetaData,
  rackType,
  isPalletRotated,
  palletsCount,
  racksCount
) => {
  let locationOrientation = locationMetaData.locationOrientation;

  const plantMetaData = JSON.parse(getLocalStorageItem("plantMetaData"));

  if (plantMetaData.length !== 0) {
    const layoutOrientation =
      plantMetaData &&
      plantMetaData.find(
        (configItem) => configItem.configName == "LAYOUT_ORIENTATION"
      );
    const { configValue } = layoutOrientation;

    switch (rackType) {
      case "RACK":
        switch (configValue) {
          case "N":
            if (locationOrientation === "S" || locationOrientation === "E") {
              return (
                <div
                  className={`pallet pallet-rack-single ${getPalletColors({
                    ...locationMetaData,
                    ...palletsCount,
                  })} ${!isPalletRotated ? "rotate-rack" : ""}`}
                >
                  {thirdPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        3,
                        true
                      )
                    : null}
                  {secondPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        2,
                        false
                      )
                    : null}
                  {firstPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        1,
                        false
                      )
                    : null}
                </div>
              );
            } else {
              return (
                <div
                  className={`pallet pallet-rack-single ${getPalletColors({
                    ...locationMetaData,
                    ...palletsCount,
                  })} ${!isPalletRotated ? "rotate-rack" : ""}`}
                >
                  {firstPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        1,
                        false
                      )
                    : null}
                  {secondPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        2,
                        false
                      )
                    : null}
                  {thirdPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        3,
                        true
                      )
                    : null}
                </div>
              );
            }
          case "S":
            if (locationOrientation === "S" || locationOrientation === "E") {
              return (
                <div
                  className={`pallet pallet-rack-single ${getPalletColors({
                    ...locationMetaData,
                    ...palletsCount,
                  })} ${!isPalletRotated ? "rotate-rack" : ""}`}
                >
                  {firstPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        1,
                        false
                      )
                    : null}
                  {secondPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        2,
                        false
                      )
                    : null}
                  {thirdPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        3,
                        true
                      )
                    : null}
                </div>
              );
            } else {
              return (
                <div
                  className={`pallet pallet-rack-single ${getPalletColors({
                    ...locationMetaData,
                    ...palletsCount,
                  })} ${!isPalletRotated ? "rotate-rack" : ""}`}
                >
                  {thirdPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        3,
                        true
                      )
                    : null}
                  {secondPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        2,
                        false
                      )
                    : null}
                  {firstPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        1,
                        false
                      )
                    : null}
                </div>
              );
            }
        }
      case "RACK_2":
      default:
        switch (configValue) {
          case "N":
            if (locationOrientation === "S" || locationOrientation === "E") {
              return (
                <div
                  className={`pallet pallet-rack-single ${getPalletColors({
                    ...locationMetaData,
                    ...palletsCount,
                  })} ${!isPalletRotated ? "rotate-rack" : ""}`}
                >
                  {thirdPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        3,
                        true
                      )
                    : null}
                  {secondPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        2,
                        false
                      )
                    : null}
                  {firstPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        1,
                        false
                      )
                    : null}
                </div>
              );
            } else {
              return (
                <div
                  className={`pallet pallet-rack-single ${getPalletColors({
                    ...locationMetaData,
                    ...palletsCount,
                  })} ${!isPalletRotated ? "rotate-rack" : ""}`}
                >
                  {firstPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        1,
                        false
                      )
                    : null}
                  {secondPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        2,
                        false
                      )
                    : null}
                  {thirdPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        3,
                        true
                      )
                    : null}
                </div>
              );
            }
          case "S":
            if (locationOrientation === "S" || locationOrientation === "E") {
              return (
                <div
                  className={`pallet pallet-rack-single ${getPalletColors({
                    ...locationMetaData,
                    ...palletsCount,
                  })} ${!isPalletRotated ? "rotate-rack" : ""}`}
                >
                  {thirdPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        3,
                        true
                      )
                    : null}
                  {secondPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        2,
                        false
                      )
                    : null}
                  {firstPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        1,
                        false
                      )
                    : null}
                </div>
              );
            } else {
              return (
                <div
                  className={`pallet pallet-rack-single ${getPalletColors({
                    ...locationMetaData,
                    ...palletsCount,
                  })} ${!isPalletRotated ? "rotate-rack" : ""}`}
                >
                  {firstPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        1,
                        false
                      )
                    : null}
                  {secondPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        2,
                        false
                      )
                    : null}
                  {thirdPosition
                    ? renderPalletPosition(
                        rackType,
                        isPalletRotated,
                        locationMetaData,
                        racksCount,
                        3,
                        true
                      )
                    : null}
                </div>
              );
            }
        }
    }
  }
};

export const renderRackPallet = (renderList, racksCount) => {
  if (renderList) {
    const rackTypeOfThisLocation = getRackType(renderList[0]);
    const locationMetaData = getMetaDataOfRackLocation(renderList[0]);
    const isPalletRotated = shouldRotatePallet(
      locationMetaData.angle,
      locationMetaData.rackType
    );

    const palletsCount = {
      count: renderList[0]?.reduce((acc, curr) => {
        acc += Number(!!curr.lpn);
        return acc;
      }, 0),
      totalCount: renderList[0]?.length,
    };

    switch (rackTypeOfThisLocation) {
      case "RACK":
        //Single Deep Racks
        const singleRackGroupedData = groupRackWithRowAsKey(renderList[0]);

        //Available Rows in the Single Deep Rack location
        const [firstRowOfPallet, secondRowOfPallet, thirdRowOfPallet] =
          Object.values(singleRackGroupedData);

        return (
          <div className="pallets-wrapper-col">
            <div
              className={`${
                locationMetaData.locationOrientation === "E"
                  ? "pallet-col-reverse"
                  : "pallet-col"
              }`}
            >
              {getRackPositionWithOrientation(
                firstRowOfPallet,
                secondRowOfPallet,
                thirdRowOfPallet,
                locationMetaData,
                rackTypeOfThisLocation,
                isPalletRotated,
                palletsCount,
                racksCount
              )}
            </div>
          </div>
        );
      case "RACK_2":
        //Double Deep Racks
        const doubleRackGroupedData = groupRackWithPalletPositionAsKey(
          renderList[0]
        );

        //Available Pallet Position in the Double Deep Rack Location
        const [
          firstPositionOfPallet,
          secondPositionOfPallet,
          thirdPositionOfPallet,
        ] = Object.values(doubleRackGroupedData);

        return (
          <div className="pallets-wrapper-col">
            <div
              className={`${
                locationMetaData.locationOrientation === "E"
                  ? "pallet-col-reverse"
                  : "pallet-col"
              }`}
            >
              {getRackPositionWithOrientation(
                firstPositionOfPallet,
                secondPositionOfPallet,
                thirdPositionOfPallet,
                locationMetaData,
                rackTypeOfThisLocation,
                isPalletRotated,
                palletsCount,
                racksCount
              )}
            </div>
          </div>
        );
      default:
        return <></>;
    }
  }
};

export const renderPreFilledSingleBlockPallet = (
  renderList,
  isLocationVertical,
  blocksCount
) => {
  //Get the PreFilled Empty Pallet List
  let preFilledRenderList = preFillPalletPositionIfEmpty(renderList);

  return (
    preFilledRenderList && (
      <div
        className={`pallets-wrapper-row 
    `}
      >
        {Object.values(preFilledRenderList).map((pallet, index) => {
          if (pallet) {
            return renderDoublePalletLocationWithReverseOrder(
              pallet,
              index,
              blocksCount
            );
          }
        })}
      </div>
    )
  );
};

export const rotateMarker = (
  angle,
  locationOrientation,
  positionOrientation
) => {
  if (angle == 180) return 0;
  if (positionOrientation == "W" && angle == -90) return 90;
  if (positionOrientation == "E" && angle == 90) return -90;
  return angle;
};

const getRotationOfMarker = (isLocationVertical, locationMetaData) => {
  if (isLocationVertical) {
    if (locationMetaData.positionOrientation == "E") {
      return "rotate-rack-90";
    }
    return "rotate-rack";
  }
  return "";
};

/**
 *
 * NOTE:
 * !!!!!!!-For Margin Offset of Single Stack Pallet, providing value in multiples of 5 is recommended---!!!!!!!
 * @returns
 */
export const renderPreFilledDoubleBlockPallet = (
  sectionsData,
  isLocationVertical,
  locationMetaData,
  blocksCount,
  marker
) => {
  // let singleStackMarkerOffset = marker.singleStack.offset;
  // const { x, y } = singleStackMarkerOffset;

  // if (locationMetaData.pbcRows !== null) {
  //   sectionsData = shiftPalletData(sectionsData);
  // }

  return (
    <div className={`pallets-wrapper-col`}>
      {sectionsData.map((sectionDouble, index) => {
        if (sectionDouble) {
          let totalDoubleRowsOfLocation = locationMetaData.doubleRows;
          let reversedArray = sectionDouble.reduce(
            (acc, item) => [item].concat(acc),
            []
          );
          //PreFill reversedArray data here
          let preFilledReversedPalletList = preFillPalletPositionIfEmpty(
            reversedArray,
            index
          );
          if (locationMetaData.positionOrientation == "N") {
            if (index < locationMetaData.pbcRows) {
              return (
                reversedArray && (
                  <span className="single-pallet-wrapper">
                    <div
                      key={index}
                      className={`${
                        sectionDouble[0][1][0].locationOrientation === "E"
                          ? `pallets-wrapper-single-row-reverse`
                          : `pallets-wrapper-single-row`
                      } ${replaceDotWithDash(locationMetaData.location)}`}
                    >
                      {Object.values(reversedArray).map((pallet, index) => {
                        //Render Single Pallet
                        return renderSinglePalletLocation(
                          pallet,
                          index,
                          blocksCount
                        );
                      })}
                    </div>
                  </span>
                )
              );
            }
            return (
              reversedArray && (
                <div
                  key={index}
                  className={`${
                    sectionDouble[0][1]?.[0].locationOrientation === "E" ||
                    sectionDouble[0][2]?.[0].locationOrientation === "E"
                      ? "pallets-wrapper-row-reverse"
                      : "pallets-wrapper-row"
                  }`}
                >
                  {Object.values(reversedArray).map((pallet, index) => {
                    //Render Double Pallet
                    return renderDoublePalletLocation(
                      pallet,
                      index,
                      blocksCount
                    );
                  })}
                </div>
              )
            );
          }
          if (index + 1 > totalDoubleRowsOfLocation) {
            return (
              reversedArray && (
                <span className="single-pallet-wrapper">
                  <div
                    key={index}
                    className={`${
                      sectionDouble[0][1][0].locationOrientation === "E"
                        ? `pallets-wrapper-single-row-reverse`
                        : `pallets-wrapper-single-row`
                    } ${replaceDotWithDash(locationMetaData.location)}`}
                  >
                    {Object.values(reversedArray).map((pallet, index) => {
                      //Render Single Pallet
                      return renderSinglePalletLocation(
                        pallet,
                        index,
                        blocksCount
                      );
                    })}
                  </div>
                </span>
              )
            );
          } else {
            return (
              reversedArray && (
                <div
                  key={index}
                  className={`${
                    sectionDouble[0][1]?.[0].locationOrientation === "E" ||
                    sectionDouble[0][2]?.[0].locationOrientation === "E"
                      ? "pallets-wrapper-row-reverse"
                      : "pallets-wrapper-row"
                  }`}
                >
                  {Object.values(reversedArray).map((pallet, index) => {
                    //Render Double Pallet
                    return renderDoublePalletLocation(
                      pallet,
                      index,
                      blocksCount
                    );
                  })}
                </div>
              )
            );
          }
        }
      })}
    </div>
  );
};

export const getBlockRackDataWithLocationOrientation = (
  locationOrientation,
  sectionsData,
  type
) => {
  switch (locationOrientation) {
    case "S":
    case "W":
      return type === "block" ? sectionsData?.[0] : sectionsData;
    case "N":
    case "E":
      return type === "block"
        ? sectionsData?.[0].reduce((acc, item) => [item].concat(acc), [])
        : sectionsData;
    default:
      return sectionsData;
  }
};

export const getLocationOrientationOfStorage = (type, sectionsData) => {
  if (sectionsData[0] !== undefined) {
    const [firstArrayItem] = sectionsData[0];
    const [firstChildOfPallet] = Object.values(firstArrayItem);
    if (type === "block") {
      if (firstArrayItem && firstChildOfPallet) {
        return firstChildOfPallet.locationOrientation
          ? firstChildOfPallet.locationOrientation
          : firstChildOfPallet[0].locationOrientation;
      }
    }
    return firstArrayItem.locationOrientation;
  }
};

export const renderDoubleEmptyPalletLocation = (index) => {
  return (
    <div key={index} className="pallet-col">
      <div className={`pallet pallet-double empty-pallet`}>
        <p>0</p>
      </div>
      <div className={`pallet pallet-double empty-pallet`}>
        <p>0</p>
      </div>
    </div>
  );
};

export const renderSingleEmptyRowPalletLocation = (index) => {
  return (
    <div key={index} className="pallet-col">
      <div className={`pallet pallet-single empty-pallet`}>
        <p>0</p>
      </div>
      <div className={`pallet pallet-single empty-pallet`}>
        <p>0</p>
      </div>
    </div>
  );
};

export const renderDoubleEmptyColumnPalletLocation = (sectionSingle, index) => {
  return (
    <div
      key={index}
      className={`${
        sectionSingle[1][0].locationOrientation === "E"
          ? "pallet-col-reverse"
          : "pallet-col"
      }`}
    >
      <div className={`pallet pallet-single empty-pallet`}>
        {sectionSingle[1][0].palletLevel}
      </div>
      {sectionSingle[2] && sectionSingle[2][0] && (
        <div className={`pallet pallet-single empty-pallet`}>
          {sectionSingle[2][0].palletLevel}
        </div>
      )}
    </div>
  );
};
// --RollBack if needed
export const findIsLocationVertical = (renderList) => {
  let angle;

  if (renderList?.[0]) {
    if (renderList[0][1] && renderList[0][1][0]) {
      angle = renderList[0][1][0].angle;
    } else if (renderList[0][2] && renderList[0][2][0]) {
      angle = renderList[0][2][0].angle;
    }
  }
  if (angle == 90 || angle == -90) {
    return true;
  }
};

export const formatName = (locationName) => {
  const splittedString = locationName.split(".");
  const result = splittedString.slice(0, splittedString.length - 1);
  return result.join(".");
};

export const getBlockRackName = (modalType, locationName) => {
  if (modalType) {
    let splittedName = locationName.split(".");
    return splittedName.slice(0, splittedName.length - 1).join(".");
  } else {
    return locationName;
  }
};

export const getLocationGroupOffset = (convertedValue, offsetObject) => {
  if (convertedValue && offsetObject) {
    //Offset Object
    let offsetCoordinates = {
      x: convertedValue.lat + offsetObject.x,
      y: convertedValue.lng + offsetObject.y,
    };
    //Offset Array
    let offsetCoordinateArray = [offsetCoordinates.x, offsetCoordinates.y];
    return offsetCoordinateArray;
  }
};

export const getDestinationPointerOffset = (convertedValue, offsetObject) => {
  if ((convertedValue, offsetObject)) {
    let sourceOffset = [
      convertedValue.currentPos.lat +
        offsetObject.coordinateCorrection.source.x,
      convertedValue.currentPos.lng +
        offsetObject.coordinateCorrection.source.y,
    ];

    let destinationOffset = [
      convertedValue.destinationCurrentPos.lat + offsetObject.value.x,
      convertedValue.destinationCurrentPos.lng + offsetObject.value.y,
    ];

    return [sourceOffset, destinationOffset];
  }
};

export const getDestinationPointerOffsetV2 = (
  convertedValue,
  coordinateCorrection
) => {
  if ((convertedValue, coordinateCorrection)) {
    let sourceOffset = [
      convertedValue.currentPos.lat + coordinateCorrection.source.x,
      convertedValue.currentPos.lng + coordinateCorrection.source.y,
    ];

    let destinationOffset = [
      convertedValue.destinationCurrentPos.lat +
        coordinateCorrection.destination.x,
      convertedValue.destinationCurrentPos.lng +
        coordinateCorrection.destination.y,
    ];

    return [sourceOffset, destinationOffset];
  }
};

export const getPlantImage = () => {
  const plantImages = {
    CAR: CARMap,
    ATL: ATLMap,
    DET: DETMap,
    ALA: ALAMap,
    HAZ: HAZMap,
    HOU: HOUMap,
    LAS: LASMap,
    MIA: MIAMap,
    TAC: TACMap,
    JAX: JAXMap,
    LAN: LANMap,
    ST3: ST3Map,
    BAL: BALMap,
    KC2: KC2Map,
    MOR: MORMap,
    TEM: TEMMap,
    LOU: LOUMap,
    BAY: BAYMap,
    RCH: RCHMap,
    COL: COLMap,
    KNC: KNCMap,
    BLM: BLMMap,
    KEN: KENMap,
    SAN: SANMap,
    SL2: SL2Map,
    MES: MESMap,
    MIS: MISMap,
    PIT: PITMap,
    MTY: MTYMap,
    OKC: OKCMap,
    MXC: MXCMap,
    NOR: NORMap,
  };
  return plantImages[getLocalStorageItem("locationCode")];
};

export const getPlantMapImageUrl = (config, locationCode, imageType) => {
  const {
    AZURE_BLOB_BASE_URL,
    WAREHOUSE_CONTAINER_URI,
    WAREHOUSE_REF_CONTAINER_URI,
    YARD_CONTAINER_URI,
    YARD_REF_CONTAINER_URI,
  } = config;
  if (!locationCode && !imageType) return null;
  // console.log(`${AZURE_BLOB_BASE_URL}${WAREHOUSE_CONTAINER_URI}NOR.png`);

  switch (imageType) {
    case "WAREHOUSE_MAP":
      return `${AZURE_BLOB_BASE_URL}${WAREHOUSE_CONTAINER_URI}${locationCode}.png`;
    case "WAREHOUSE_MAP_REF":
      return `${AZURE_BLOB_BASE_URL}${WAREHOUSE_REF_CONTAINER_URI}${locationCode}.png`;
    case "YARD_MAP":
      return `${AZURE_BLOB_BASE_URL}${YARD_CONTAINER_URI}${locationCode}.avif`;
    case "YARD_MAP_REF":
      return `${AZURE_BLOB_BASE_URL}${YARD_REF_CONTAINER_URI}${locationCode}.avif`;
    default:
      return `${AZURE_BLOB_BASE_URL}${WAREHOUSE_CONTAINER_URI}${locationCode}.png`;
  }
};

export const getUserAuthPayload = (userData) => {
  if (userData) {
    let payload = {};
    const { oid, name, email } = userData;
    Object.assign(payload, userDataObject);
    payload.mail = email;
    payload.displayName = name;
    payload.id = oid;
    return payload;
  }
};

export const setBlockStorageSize = (blockSize) => {
  const { blockWidth, blockHeight, singleStackWidth, singleStackHeight } =
    blockSize;
  //Get the Root of the DOM
  let root = document.querySelector(":root");
  //Set Block Storage Height and Width
  root.style.setProperty("--block-storage-width", blockWidth);
  root.style.setProperty("--block-storage-height", blockHeight);

  //Set Block Single Stack Height and Width
  root.style.setProperty(
    "--block-single-stack-storage-width",
    singleStackWidth
  );
  root.style.setProperty(
    "--block-single-stack-storage-height",
    singleStackHeight
  );

  //Set Block Single Stack Start Margin
};

export const setVerticalBlockStorageSize = (blockSize) => {
  const { blockWidth, blockHeight, singleStackWidth, singleStackHeight } =
    blockSize;
  //Get the Root of the DOM
  let root = document.querySelector(":root");
  //Set Block Storage Height and Width
  root.style.setProperty("--vblock-storage-width", blockWidth);
  root.style.setProperty("--vblock-storage-height", blockHeight);

  //Set Block Single Stack Height and Width
  root.style.setProperty(
    "--vblock-single-stack-storage-width",
    singleStackWidth
  );
  root.style.setProperty(
    "--vblock-single-stack-storage-height",
    singleStackHeight
  );

  //Set Block Single Stack Start Margin
};

export const setRackStorageSize = (rackSize) => {
  const { rackWidth, rackHeight } = rackSize;
  //Get the Root of the DOM
  let root = document.querySelector(":root");
  //Set Block Storage Height and Width
  root.style.setProperty("--rack-storage-width", rackWidth);
  root.style.setProperty("--rack-storage-height", rackHeight);
};

export const getPbcAndDoubleRowMaxCount = (locationMetaData) => {
  const { doubleRows, pbcRows, totalPositions, transitionPallet } =
    locationMetaData;
  let maxPbcRowCount = transitionPallet;
  let maxDoubleRowCount = totalPositions / 2 - transitionPallet / doubleRows;

  return { pbc: maxPbcRowCount, double: maxDoubleRowCount };
};

export const isPbcRowAtTop = (locationMetaData) => {
  const { positionOrientation } = locationMetaData;
  return positionOrientation === "S" ? true : false;
};

export const shiftPalletData = (palletsList) => {
  let sectionList = structuredClone(palletsList);
  let count = sectionList.length;

  for (let i = 0; i < Math.floor(count / 2); i++) {
    let oppIndex = count - 1 - i;
    let minLength = Math.min(
      sectionList[i].length,
      sectionList[oppIndex].length
    );

    for (let j = 0; j < minLength; j++) {
      [sectionList[i][j], sectionList[oppIndex][j]] = [
        sectionList[oppIndex][j],
        sectionList[i][j],
      ];
    }
  }

  return sectionList;
};

export const isBoundsValid = (bounds, maxBounds) => {
  if (
    bounds.startY >= maxBounds.startX &&
    bounds.endY <= maxBounds.endX &&
    bounds.startX >= maxBounds.startX &&
    bounds.endX <= maxBounds.endY
  ) {
    return true;
  }
  return false;
};

export const getTimeBasedOnUserTime = (plantTimeStamp) => {
  if (!plantTimeStamp) return "";
  let [date, time] = plantTimeStamp.split("T");
  time = time.split(":");
  time = `${time[0]}:${time[1]}:${time[2].split(".")[0]}`;
  const timeOfPlant = momentTZ.tz(
    `${date} ${time}`,
    getLocalStorageItem("timeZone")
  );
  const timeOfUser = timeOfPlant
    .clone()
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
  // returning raw time, format it accordingly
  return timeOfUser;
};

/**
 * Splits an array into smaller chunks of a specified size.
 *
 * @param {Array} array - The array to be split into chunks.
 * @param {number} chunkSize - The size of each chunk.
 * @returns {Array} - An array containing the smaller chunks.
 */
export const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};
